@import '../../../../scss/theme-bootstrap';

.content-block-basic-tout {
  display: flex;
  flex-wrap: wrap;
  flex-flow: column;
  &__media {
    @include breakpoint($bp--large-up) {
      width: 100%;
    }
  }
  &__content-wrapper {
    &.content-block__content-below-media {
      display: flex;
      align-items: center;
      flex: 1;
      flex-wrap: wrap;
    }
  }
  &-content {
    display: flex;
    flex-flow: column;
    width: 100%;
  }
}
